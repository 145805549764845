<template>
    <swiper ref="swiper" :options="swiperOptions" class="h-full w-full">
        <SliderPercentage :percentage="25" :sectorPercentage="sectors['25']" />
        <SliderPercentage :percentage="50" :sectorPercentage="sectors['50']" />
        <SliderPercentage :percentage="75" :sectorPercentage="sectors['75']" />
        <SliderPercentage :percentage="100" :sectorPercentage="sectors['100']" />
        <div class="swiper-pagination -mb-2" slot="pagination"></div>
    </swiper>
</template>

<script>
    const SliderPercentage = () => import("@/components/Mobile/Pipeline/PipelineCircle/PipelineByPercentage/SliderPercentage");
    export default {
        name: "PipelineByPercentage",
        props: ['sectors'],
        components: {
            SliderPercentage
        }
    }
</script>